import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Ratio } from 'react-bootstrap';
import { Wrapper, Section, SectionTitles, SectionTitle, SectionSubtitle } from './styles';

// mp4s
import nikeMp4 from '../../videos/03-nike-golf-club-02.mp4';
import maynMp4 from '../../videos/08-mayn-creative-02.mp4';

const Projects = () => {

	const createVideo = (url) => {
		return (
			<video loop muted autoPlay playsInline>
				<source src={url} type="video/mp4" />
			</video>
		);
	}

	const agency = `Agency: Mother Design`

	const clients = {
		bbcRadio1: `BBC Radio 1`,
		jumboShrimp: `Jumbo Shrimp`,
		nikeGolfClub: `Nike Golf Club`,
		facebookCampus: `Facebook Campus`,
		bbcSounds: `BBC Sounds`,
		nuud: `Nuud`,
		barbicanAi: `Barbican AI: More than Human`,
		maynCreative: `Mayn Creative`,
		pepsi: `Pepsi`,
	}

	return (
		<Wrapper>

			{/* BBC Radio 1 */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.bbcRadio1}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/01-bbc-radio-1-01.jpg" alt={clients.bbcRadio1} />
				<Ratio aspectRatio={1072 / 1600}>
					{createVideo(`https://player.vimeo.com/external/310523844.hd.mp4?s=99715e7a244d7db4aafc29ba450d30371a92ae71&profile_id=175`)}
				</Ratio>
				<StaticImage src="../../images/01-bbc-radio-1-03.jpg" alt={clients.bbcRadio1} />
				<Ratio aspectRatio={1080 / 1920}>
					{createVideo(`https://player.vimeo.com/external/290978894.hd.mp4?s=1b649451e05d0639a4ee20f98898b7c43c23ca00&profile_id=175`)}
				</Ratio>
			</Section>

			{/* Jumbo Shrimp */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.jumboShrimp}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/02-jumbo-shrimp-01.jpg" alt={clients.jumboShrimp} />
				<Ratio aspectRatio={1080 / 1922}>
					{createVideo(`https://player.vimeo.com/external/592754336.hd.mp4?s=c19d6102eb93bfda9bcd11cbf4419c84bf387261&profile_id=175`)}
				</Ratio>
				<StaticImage src="../../images/02-jumbo-shrimp-03.jpg" alt={clients.jumboShrimp} />
			</Section>

			{/* Nike Golf Club */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.nikeGolfClub}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/03-nike-golf-club-01.jpg" alt={clients.nikeGolfClub} />
				<Ratio aspectRatio={1575 / 2800}>
					{createVideo(nikeMp4)}
				</Ratio>
			</Section>

			{/* Facebook Campus */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.facebookCampus}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/04-facebook-campus-01.jpg" alt={clients.facebookCampus} />
				<Ratio aspectRatio={1080 / 1920}>
					{createVideo(`https://player.vimeo.com/external/577612483.hd.mp4?s=5f66e99bafc4a070067f53e9e3f0a7360335a6ea&profile_id=175`)}
				</Ratio>
			</Section>

			{/* BBC Sounds */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.bbcSounds}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/05-bbc-sounds-01.jpg" alt={clients.bbcSounds} />
				<StaticImage src="../../images/05-bbc-sounds-02.jpg" alt={clients.bbcSounds} />
				<StaticImage src="../../images/05-bbc-sounds-03.jpg" alt={clients.bbcSounds} />
			</Section>

			{/* Nuud */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.nuud}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/06-nuud-01.jpg" alt={clients.nuud} />
				<StaticImage src="../../images/06-nuud-02.jpg" alt={clients.nuud} />
			</Section>

			{/* Barbican AI */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.barbicanAi}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/07-barbican-ai-more-than-human.jpg" alt={clients.barbicanAi} />
			</Section>

			{/* Mayn Creative */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.maynCreative}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/08-mayn-creative-01.jpg" alt={clients.maynCreative} />
				<Ratio aspectRatio={1575 / 2800}>
					{createVideo(maynMp4)}
				</Ratio>
			</Section>

			{/* Pepsi */}
			<Section>
				<SectionTitles>
					<SectionTitle>{clients.pepsi}</SectionTitle>
					<SectionSubtitle>{agency}</SectionSubtitle>
				</SectionTitles>
				<StaticImage src="../../images/09-pepsi-01.jpg" alt={clients.pepsi} />
				<Ratio aspectRatio={1080 / 1920}>
					{createVideo(`https://player.vimeo.com/external/555716005.hd.mp4?s=820742636b367cf9f957cd7cc6937012bc1d6680&profile_id=175`)}
				</Ratio>
			</Section>

		</Wrapper>
	)
}

export default Projects;
