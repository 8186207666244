import styled from 'styled-components';

export const Wrapper = styled.header`
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1054;
`;

export const Container = styled.div`
	align-items: center;
	background-color: #fff;
	display: flex;
	height: 2.4rem; // 48 px
	justify-content: space-between;
	padding: 0 1.5rem 0 1rem;
	width: 100%;
`;

export const Title = styled.h1`
	font-size: 1rem;
`;

export const Menu = styled.ul`
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

export const MenuItem = styled.li`
	&:not(:last-child) {
		margin-right: 1rem;
	}
`;

export const ModalText = styled.article`
	max-width: 12rem;
	transform: translateY(-.5rem); // first line of text needs to align perfectly with header text
	
	@media all and (min-width: 576px) {
		max-width: 31.5rem;
	}
	
	a:not(:hover) {
		text-decoration: underline;
	}
`;

export const ModalClose = styled.div`
	padding: .45rem 1.5rem;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
`;

export const SocialList = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

export const SocialListItem = styled.li``;

export const Copyright = styled.p`
	margin-top: 2rem;
	
	@media all and (min-width: 576px) {
		margin-top: 0;
		text-align: right;
	}
`;

export const Credit = styled.p`
	font-size: .7rem;
	margin: 1rem 0 0;
	
	@media all and (min-width: 576px) {
		text-align: right;
	}
`;
