import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import Projects from '../components/projects/projects';
import '../scss/styles.scss';
import data from '../content/data.json';
const page = data.pages.home;

const IndexPage = () => {

	let [isLoaded] = useState(false);

	return (
		<div className={isLoaded ? `` : `cloak`}>
			<Layout>
				<Projects />
			</Layout>
		</div>
	)
}

export const Head = () => (
	<>
		<title>{page.meta_title}</title>
		<link rel="canonical" href={page.meta_url} />
		<link rel="description" content={page.meta_description} />
		<meta property="og:title" content={page.meta_title} />
		<meta property="og:type" content="website" />
		<meta property="og:url" content={page.meta_url} />
		<meta property="og:description" content={page.meta_description} />
		<meta property="og:site_name" content={data.general.site_name} />
	</>
)

export default IndexPage;
