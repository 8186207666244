import React, {useState} from 'react';
import { Wrapper, Container, Title, Menu, MenuItem, ModalClose, ModalText, SocialList, SocialListItem, Copyright, Credit } from './styles';
import { Link } from 'gatsby';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import data from '../../content/data.json';

const Header = () => {

	const [showModal, setShowModal] = useState(false);

	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);

	const today = new Date();
	const date = today.getFullYear();

	return (
		<Wrapper>
			<Container>
				<Title className={`mb-0`}>
					<Link to={`/`} aria-label={data.pages.home.meta_title}>{data.general.site_name}</Link>
				</Title>
				<nav>
					<Menu>
						<MenuItem>
							<Button className={`p-0`} variant={`link`} onClick={handleShowModal}>About</Button>
						</MenuItem>
					</Menu>
				</nav>
			</Container>

			<Modal show={showModal} fullscreen={true} animation={false} onHide={handleCloseModal}>
				<Modal.Dialog fullscreen={true}>
					<ModalClose>
						<Button className={`p-0`} variant={`link`} onClick={handleCloseModal}>Work</Button>
					</ModalClose>
					<Modal.Body>
						<ModalText>
							<div dangerouslySetInnerHTML={{__html: data.modals.about.content}} />
						</ModalText>
					</Modal.Body>
					<Modal.Footer bsPrefix={`custom-modal-footer`}>
						<Row className={`align-items-end justify-content-between`}>
							<Col xs={12} sm={6}>
								<SocialList>
									<SocialListItem>
										<a href={`mailto:thomashumeau10@gmail.com`}
										   aria-label={`Email me`}>Email</a>
									</SocialListItem>
									{data.social.links.map(link => {
										return (
											<SocialListItem key={link.type}>
												<Link to={link.url}
												      aria-label={link.type}
												      target={`_blank`}
												      rel={`noreferrer noopener`}>{link.type}</Link>
											</SocialListItem>
										)
									})}
								</SocialList>
							</Col>
							<Col xs={12} sm={6}>
								<Copyright className={`mb-0`}>All rights reserved.
									<br/>&copy; {date} {data.general.site_name}.
								</Copyright>
								<Credit>
									Developed by <a href="https://jase.io/" target="_blank" rel="noreferrer noopener">Jase</a>.
								</Credit>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal.Dialog>
			</Modal>

		</Wrapper>
	)
}

export default Header;
