import styled from 'styled-components';

export const Wrapper = styled.main`
	padding-top: 2.4rem; // height of the fixed header
`;

export const Section = styled.section`
	background-color: #000;
	position: relative;
`;

export const SectionTitles = styled.div`
	height: 100%;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
`

export const SectionTitle = styled.h2`
	color: #fff; 
	font-size: 1rem;
	margin-bottom: 0;
	padding: 1rem;
	position: sticky;
	top: 2.4rem; // height of the fixed header
	width: 50%;
`;

export const SectionSubtitle = styled.h3`
	color: #fff; 
	font-size: .6rem;
	margin-bottom: 0;
	padding: 1.25rem 1.5rem 1rem 1rem;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
	width: 50%;
`;
